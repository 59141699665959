import { useQuery } from 'react-query';
import fetch from '../../services/fetch';

const isCreated = async () => {
  try {
    const { data } = await fetch(
      `${process.env.REACT_APP_API}/v1/is-completed-registration`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
        },
      },
    )

    if(data.created === 1){
      localStorage.setItem('shop', true)
    }

    if(data.created !== 1){
      console.log('not created')
      return false
    }

    return data

  } catch (error) {
    console.log(error.message)
  }
}

export const useShopIsCreated = () => {
  return useQuery(["shopIsCreated"], isCreated)
}
