import React, { useRef, useState } from 'react'
import { Button, Container, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router';
import Page from '../../components/Page'
import CustomSelect from '../../components/Form/Select'
import { Form } from '@unform/web';
import Input from '../../components/Form/Input';
import { useCategoriesFetch } from '../../hooks/categories/useCategories.query';

function OptionalEdit() {
  const { id } = useParams();
  const formRef = useRef(null);
  const [setCategoriesList] = useState(['']);
  const { isLoading: loadingCategories, data: categories } = useCategoriesFetch()

  async function handleSubmit(productData) {
    // await mutateAsync({ ...productData, id })
  }

  const optionsCategory = loadingCategories
    ? 'carregando...'
    : categories &&
    categories.length > 0 &&
    categories.map((category) => ({
      value: category.title.replace(' ', '-'),
      label: category.title
    }));
  const categoriesOnChange = (opt, ctx) => {
    const { label } = opt;
    setCategoriesList(label);
  };

  console.log(id)

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: 'var(--color-input-placeholder)',
      color: state.isSelected
        ? 'var(--color-primary)'
        : 'var(--color-input-placeholder)',
      backgroundColor: state.isSelected
        ? 'var(--color-background-dark)'
        : 'var(--color-input-background)'
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      backgroundColor: 'var(--color-input-background)',
      marginBottom: '10px',
      border: '1px solid var(--color-input-border)'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
        color: 'var(--color-input-placeholder)'
      };
    }
  };


  return (
    <Page title="Criar Produtos">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Cadastro de Produto
          </Typography>
        </Stack>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="name" type="text" placeholder="Nome do opcional" />
          <Input placeholder="Valor" type="text" name="price" />
          <CustomSelect
            label='Categoria'
            isSearchable
            name="choices_list"
            options={optionsCategory}
            onChange={categoriesOnChange}
            styles={customStyles}
            visible={true}
            required
          />

          <hr className="order" />
          <Button type="submit" variant="outlined" size="large" > Salvar </Button>

        </Form>
      </Container>
    </Page>
  )
}

export default OptionalEdit
