import { toast } from 'react-toastify';
import fetch from '../../services/fetch';

const Signup = async (dataForm) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/site/signup`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataForm)
    },
  )

  if (response.status === 201) {
    toast.success(`${response.message}`, {
      position: toast.POSITION.TOP_CENTER
    });
    return null
  }

  toast.error(`${response.message}`, {
    position: toast.POSITION.TOP_CENTER
  });

  return response.message
}


export const useSignup = async (data) => {
  return Signup(data)
}
