import React, { useRef, useState } from 'react'
import { Button, Container, Divider, Stack, Typography } from '@mui/material'
import { Form } from '@unform/web'
import Page from '../../components/Page'
import { useProductCreate } from '../../hooks/products/useProducts.query';
import { useMutation } from 'react-query';
import CustomSelect from '../../components/Form/Select';
import ToggleSwitch from '../../components/Form/ToggleSwitch'
import { useOptionalFetch } from '../../hooks/optional/useOptional.query'
import { useCategoriesFetch } from '../../hooks/categories/useCategories.query'
import TextField from '../../components/Form/TextField';

function ProductsCreate() {
  const formRef = useRef(null);
  
  const [isVegetarian, setIsVegetarian] = useState(false);
  const [isOptional, setIsOptional] = useState(false);
  const [optionalIsActive, setOptionalIsActive] = useState('');
  const [inputList] = useState([{ sizeWeight: '', sizePrice: '', peopleOptions: '' }]);
  const [inputOptionalList, setInputOptionalList] = useState([{ optional: '' }]);
  const [categoriesList, setCategoriesList] = useState(['']);

  const { isLoading: loadingOptional, data: optional } = useOptionalFetch()
  const { isLoading: loadingCategories, data: categories } = useCategoriesFetch()

  const { mutateAsync, isLoading: saving } = useMutation(useProductCreate, {
    onSettled: () => {
      // queryClient.invalidateQueries('products')
    },
    onSuccess: () => {
      // queryClient.invalidateQueries('products')
    }
  })

  async function handleSubmit(dataForm) {
    const form = dataForm;
    const sizes = { sizes: inputList };
    const category = categoriesList;
    const optional = inputOptionalList;
    const dataProducts = {
      category,
      optional,
      // withOptional,
      ...form,
      ...sizes
    };
    console.log(dataProducts);
    await mutateAsync(dataProducts)
  }

  const handleChangeThenOptional = (event) => {
    setIsOptional(!isOptional)
    setOptionalIsActive(!optionalIsActive);
  };

  const optionsCategory = loadingCategories
    ? 'carregando...'
    : categories &&
    categories.length > 0 &&
    categories.map((category) => ({
      value: category.title.replace(' ', '-'),
      label: category.title
    }));
  const categoriesOnChange = (opt, ctx) => {
    const { label } = opt;
    setCategoriesList(label);
  };
  const optionsOptional = loadingOptional
    ? 'carregando...'
    : optional &&
    optional.length > 0 &&
    optional.map((category) => ({
      value: category.id,
      label: category.name
    }));
  const handleChangeOptional = (value) => {
    setInputOptionalList(value);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: 'var(--color-input-placeholder)',
      color: state.isSelected
        ? 'var(--color-primary)'
        : 'var(--color-input-placeholder)',
      backgroundColor: state.isSelected
        ? 'var(--color-background-dark)'
        : 'var(--color-input-background)'
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      backgroundColor: 'var(--color-input-background)',
      marginBottom: '10px',
      border: '1px solid var(--color-input-border)'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
        color: 'var(--color-input-placeholder)'
      };
    }
  };

  if (saving) {
    return (
      <Typography variant="h4" gutterBottom>
        Salvando
      </Typography>
    )
  }

  return (
    <Page title="Criar Produtos">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Cadastro de Produto
          </Typography>
        </Stack>

        <Form ref={formRef} onSubmit={handleSubmit}>

          <ToggleSwitch
            name="isVegetarian"
            label="É Vegetariano ?"
            value={isVegetarian}
            onColor="#ea7c69"
            handleToggle={() => setIsVegetarian(!isVegetarian)}
            isOn={isVegetarian}
          />

          <TextField
            name="cuisine"
            type="text"
            label="Tipo de cozinha"
            fullWidth
          />

          <TextField
            name="name"
            type="text"
            label="Nome do prato"
            fullWidth
            required
          />
          <TextField
            name="description"
            type="text"
            label="Descrição do prato"
            fullWidth
            required
          />

          <TextField
            name="price"
            type='text'
            label="Preço"
            fullWidth
          />
          <TextField
            name="regular_price"
            type='text'
            label="Preço Normal"
            fullWidth
          />

          <TextField
            name="for_people"
            type='text'
            label="Serve Quantas Pessoas"
            fullWidth
          />

          <TextField
            name="ingredients"
            type="text"
            label="ingredients"
            fullWidth
          />


          {
            optional && optional.length > 0 ? (
              <>
                <ToggleSwitch
                  label="Tem Opcionais?"
                  name="isOptional"
                  value={isOptional}
                  onColor="#ea7c69"
                  handleToggle={handleChangeThenOptional}
                  isOn={isOptional}
                />

                <CustomSelect
                  isMulti
                  label='Opcionais'
                  name="optional"
                  options={optionsOptional}
                  onChange={handleChangeOptional}
                  styles={customStyles}
                  visible={optionalIsActive}
                />
              </>
            ) : (null)
          }


          {
            categories && categories.length > 0 ? (
              <CustomSelect
                label='Categoria'
                isSearchable
                name="category"
                options={optionsCategory}
                onChange={categoriesOnChange}
                styles={customStyles}
                visible={true}
                required
              />
            ) : (null)
          }



          <TextField
            name="tags"
            type="text"
            label="Tag"
            fullWidth
          />

          <Divider />

          <Button type="submit" variant="outlined" size="large" > Salvar </Button>
        </Form>
      </Container>
    </Page>
  )
}

export default ProductsCreate
