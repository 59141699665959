import React, { useEffect, useRef, useState } from 'react'
import { 
  Card, 
  CardHeader, 
  Skeleton, 
  Table, 
  TableBody, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography } from '@mui/material'
import { alpha, Box, styled } from '@mui/system';
import Radio from '../Form/Radio'
import { Form } from '@unform/web';
import { useMutation, useQueryClient } from 'react-query';
import { useDeliveryFeeFetch, useDeliveryFeeUpdate } from '../../hooks/deliveryFee/useDeliveryFee.query'
import { LoadingButton } from '@mui/lab';
import TextField from '../Form/TextField';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(0, 0),
  margin: theme.spacing(4, 0),
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.grey[800], 0.72)
}));

const TableCellStyle = styled('td')(({ theme }) => ({
  color: theme.palette.text.light,
}));


function DeliveryFee() {
  const formRef = useRef(null);
  const queryClient = useQueryClient()
  const [typesValuesDelivery, setTypesValuesDelivery] = useState({ free: false, fix: false, distance: false });
  const { isLoading, isError, data } = useDeliveryFeeFetch()

  const { mutateAsync, isLoading: saving } = useMutation(useDeliveryFeeUpdate, {
    onSettled: () => {
      queryClient.invalidateQueries('delivery-fee')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('delivery-fee')
    }
  })

  const handleSubmit = async (deliveryFeeData) => {
    const id = data.id;
    await mutateAsync({ deliveryFeeData, id })
  };

  const radioOptions = [
    { id: 'free', value: 'free', label: 'Grátis' },
    { id: 'fix', value: 'fix', label: 'Fixo' },
    { id: 'distance', value: 'distance', label: 'Distancia' }
  ];

  const handleChangeType = (e) => {
    const type = e.target.value;
    type === 'free' && setTypesValuesDelivery({ free: true, fix: false, distance: false });
    type === 'fix' && setTypesValuesDelivery({ free: false, fix: true, distance: false });
    type === 'distance' && setTypesValuesDelivery({ free: false, fix: false, distance: true });
  };

  useEffect(() => {
    const type = data?.type
    type === 'free' && setTypesValuesDelivery({ free: true, fix: false, distance: false });
    type === 'fix' && setTypesValuesDelivery({ free: false, fix: true, distance: false });
    type === 'distance' && setTypesValuesDelivery({ free: false, fix: false, distance: true });
  }, [data?.type])


  if (isLoading) return (
    <RootStyle>
      <Skeleton variant="rect" height={80} />
    </RootStyle>
  )
  if (isError) return (
    <RootStyle>
      <CardHeader title="Erro" />
    </RootStyle>
  )

  return (
    <RootStyle>
      <CardHeader title="Taxa de entrega" />
      <Box sx={{ pt: 2, pb: 2, px: 3 }}>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={data}
        >
          <TableContainer>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCellStyle style={{ width: 160 }}>Tipo</TableCellStyle>
                  <TableCellStyle>
                    <Radio
                      name="type"
                      options={radioOptions}
                      onChange={(e) => handleChangeType(e)}
                    />
                  </TableCellStyle>
                </TableRow>

              </TableHead>
              <TableBody>

                {typesValuesDelivery.free && (
                  <TableRow>
                    <TableCellStyle style={{ width: 160 }}></TableCellStyle>
                    <TableCellStyle>
                      <Typography variant="h4" sx={{ mb: 0 }}>
                        Entrega Grátis
                      </Typography>
                    </TableCellStyle>
                  </TableRow>
                )}

                {typesValuesDelivery.fix && (
                  <TableRow>
                    <TableCellStyle style={{ width: 160 }}>
                      <Typography variant="subtitle1" sx={{ mb: 0 }}>
                        Valor Fixo
                      </Typography>
                    </TableCellStyle>
                    <TableCellStyle>
                      <TextField
                        label="Valor"
                        fullWidth
                        type="text"
                        name="unique"
                      />
                    </TableCellStyle>
                  </TableRow>
                )}

                {typesValuesDelivery.distance && (
                  <>
                    <TableRow>
                      <TableCellStyle style={{ width: 160 }}>
                        <Typography variant="subtitle1" sx={{ mb: 0 }}>
                          ate 1km
                        </Typography>
                      </TableCellStyle>
                      <TableCellStyle>
                        <TextField name="distance_0" type="text" fullWidth label='' />
                      </TableCellStyle>
                    </TableRow>

                    <TableRow>
                      <TableCellStyle style={{ width: 160 }}>
                        <Typography variant="subtitle1" sx={{ mb: 0 }}>
                          1km ate 2km
                        </Typography>
                      </TableCellStyle>
                      <TableCellStyle>
                        <TextField name="distance_1" type="text" fullWidth label='' />
                      </TableCellStyle>
                    </TableRow>

                    <TableRow>
                      <TableCellStyle style={{ width: 160 }}>
                        <Typography variant="subtitle1" sx={{ mb: 0 }}>
                          3km ate 5km
                        </Typography>
                      </TableCellStyle>
                      <TableCellStyle>
                        <TextField name="distance_2" type="text" fullWidth label='' />
                      </TableCellStyle>
                    </TableRow>

                    <TableRow>
                      <TableCellStyle style={{ width: 160 }}>
                        <Typography variant="subtitle1" sx={{ mb: 0 }}>
                          5km ate 7km
                        </Typography>
                      </TableCellStyle>
                      <TableCellStyle>
                        <TextField name="distance_3" type="text" fullWidth label='' />
                      </TableCellStyle>
                    </TableRow>

                    <TableRow>
                      <TableCellStyle style={{ width: 160 }}>
                        <Typography variant="subtitle1" sx={{ mb: 0 }}>
                          7km ate 10km
                        </Typography>
                      </TableCellStyle>
                      <TableCellStyle>
                        <TextField name="distance_4" type="text" fullWidth label='' />
                      </TableCellStyle>
                    </TableRow>

                    <TableRow>
                      <TableCellStyle style={{ width: 160 }}>
                        <Typography variant="subtitle1" sx={{ mb: 0 }}>
                          acima de 10km
                        </Typography>
                      </TableCellStyle>
                      <TableCellStyle>
                        <TextField name="distance_5" type="text" fullWidth label='' />
                      </TableCellStyle>
                    </TableRow>
                  </>
                )}

              </TableBody>
            </Table>
          </TableContainer>

          <LoadingButton loading={saving ? true : false} type="submit" variant="outlined" size="large" style={{ width: '100%', height: 58, marginTop: 10 }}> Salvar </LoadingButton>

        </Form>
      </Box>
    </RootStyle>
  )
}

export default DeliveryFee
