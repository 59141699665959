import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import fetch from '../../services/fetch';

const getShop = async () => {
  try {
    const { data } = await fetch(
      `${process.env.REACT_APP_API}/v1/shop/get-shop`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
        },
      },
    )

    // if(status === 401) return null

    return data
  } catch (error) {
    console.log(error.message)
  }
}

const postShop = async (dataForm) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/shop/create`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify(dataForm)
    },
  )

  toast.success(`${response.message}`, {
    position: toast.POSITION.TOP_CENTER
  });
  
  return response
}

const putShop = async (dataForm) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/shop/update`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify(dataForm)
    },
  )

  return response
}


export function useShopFetch() {
  return useQuery(["shop"], () => getShop(), {
    onSuccess: (data) => {
      // localStorage.setItem('@App:user', JSON.stringify(data.name))
    }
  })
}

export const useShopCreate = async (data) => {
  return postShop(data)
}

export const useShopUpdate = async (data) => {
  return putShop(data)
}
