import React from 'react'
import { alpha, styled } from '@mui/material/styles';
import { Card, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.grey[800], 0.72)
}));

function MenuBar() {

  const items = [
    {
      title: 'Produtos',
      key: 'produtos',
      link: '/dashboard/produtos'
    },
    {
      title: 'Categorias',
      key: 'categorias',
      link: '/dashboard/categorias'
    },
    {
      title: 'Opcionais',
      key: 'opcionais',
      link: '/dashboard/opcionais'
    },
  ];

  return (
    <RootStyle>

      <nav aria-label="main mailbox folders">
        <List>

          {
            items.map(item => (
              <ListItem disablePadding key={item.key}>
                <ListItemButton component='a'>
                  <Link to={item.link}>
                    <ListItemText primary={item.title} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))
          }

        </List>
      </nav>

    </RootStyle>
  )
}

export default MenuBar
