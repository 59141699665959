import React from 'react'
import { Drawer, Typography, Backdrop, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import ShopCreate from '../shop/create';

const ContentStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  color: theme.palette.text.light,
  '&::-webkit-scrollbar': {
    width: '3px',
    height: '3px',
  },
  '&::-webkit-scrollbar-track': {
    background: ''
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
  }
}));


function Complete() {
  return (
    <>
      <Drawer
        open={true}
        anchor='right'
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', maxWidth: 650, zIndex: (theme) => theme.zIndex.drawer + 1 },
          delay: '300ms'
        }}
      >
        <ContentStyle>
          <Typography variant="h6" component="h2" sx={{ textAlign: 'center' }}>
            Finalize o cadastrado da loja
          </Typography>
          <ShopCreate />
        </ContentStyle>
      </Drawer>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default Complete
