import styled from 'styled-components';

export const TableRowStyled = styled.tr`
  border-bottom: 1px solid rgba(241, 243, 244, 1);
`

export const TableCellStatus = styled.td`
  &.status {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 12px;
    margin: 15px auto;
    width: 100px;
    border-radius: 30px;
  }
  &.pending {
    background: rgba(255, 181, 114, 0.24);
    color: #ffb572;
  }
  &.processing {
    background: rgba(146, 144, 254, 0.24);
    color: #9290fe;
  }
  &.on-hold {
    background: rgba(105, 180, 237, 0.9);
    color: #0046fb;
  }
  &.in-transit {
    background: rgba(107, 226, 190, 0.24);
    color: #50d1aa;
  }
  &.completed {
    background: rgba(107, 226, 190, 0.5);
    color: #50d1aa;
  }
  &.canceled {
    background: rgba(255, 124, 163, 0.24);
    color: #ff7ca3;
  }
  &.failed {
    background: rgba(255, 124, 163, 0.5);
    color: #ff7ca3;
  }
`;

