import React from 'react'
import { Container, Grid } from '@mui/material'
import Page from '../../components/Page'
import Profile from '../../components/_dashboard/shop/profile'
import Informations from '../../components/_dashboard/shop/informations'
import { Box } from '@mui/system'

function Shop() {
  return (
    <Page title="Shop">
      <Container maxWidth="xl">

        <Box sx={{ pb: 5, height: 350 }}>
          <Profile />
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Informations />
          </Grid>
        </Grid>

      </Container>
    </Page>
  )
}

export default Shop
