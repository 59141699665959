import React from 'react'
import { 
  Button, 
  Card, 
  CardActions, 
  CardContent, 
  Divider, 
  Link, 
  List, 
  ListItem, 
  ListItemText, 
  Typography } from '@mui/material'
import { styled } from '@mui/system';

import { useShopFetch } from '../../../hooks/shop/useShop.query';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));


function Informations() {
  const { data: shop } = useShopFetch()

  return (
    <>

      <Card sx={{ minWidth: 275, my: 3 }}>
        <CardContent>
          <Demo>
            <List>
              <ListItem>
                <Typography component="div" variant="h5" sx={{ textAlign: 'center' }}>
                  {shop?.name}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem><ListItemText primary={`${shop?.address}, ${shop?.number}`} /></ListItem>
              <ListItem><ListItemText primary={`${shop?.district}`} secondary={`${shop?.city} - ${shop?.state}`} /></ListItem>
              <Divider />
              <ListItem><ListItemText primary={shop?.postal_code} /></ListItem>
              <Divider />
              <ListItem><ListItemText primary={shop?.email} /></ListItem>
              <Divider />
              <ListItem><ListItemText primary={shop?.phone} /></ListItem>
            </List>
          </Demo>
        </CardContent>
        <CardActions>
          <Button component={Link} href="loja/editar" type="submit" variant="outlined" size="medium" sx={{ width: '100%' }}>
              Editar
          </Button>
        </CardActions>
      </Card>

      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Demo>
            <List>
              <ListItem>
                <ListItemText primary={shop?.link_generated} />
              </ListItem>
            </List>
          </Demo>
        </CardContent>
      </Card>

    </>
  )
}

export default Informations
