import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import fetch from '../../services/fetch';

const getProducts = async () => {
  const { data } = await fetch(
    `${process.env.REACT_APP_API}/v1/products/my-products`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
  return data
}

const getProduct = async (id) => {
  const { data } = await fetch(
    `${process.env.REACT_APP_API}/v1/products/view?id=${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
  return data
}

const postProduct = async (dataForm) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/products/create`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify(dataForm)
    },
  )
  toast.success(`${response.message}`, {
    position: toast.POSITION.TOP_CENTER
  });
  return response
}

const putProduct = async (dataForm) => {
  console.log(dataForm)
  // const response = await fetch(
  //   `${process.env.REACT_APP_API}/products/update?id${dataForm.id}`,
  //   {
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
  //     },
  //     body: JSON.stringify(dataForm)
  //   },
  // )

  // return response
}

export default function useProductsFetch() {
  return useQuery(["products"], getProducts)
}

export function useProductId(id) {
  return useQuery(["product", id], () => getProduct(id))
  // return getProduct(id)
}

export const useProductCreate = async (data) => {
  return postProduct(data)
}

export const useProductUpdate = async (data, id) => {
  return putProduct(data, id)
}