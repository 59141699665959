import React from 'react'
import { 
  Button, 
  Container, 
  Grid, 
  Icon, 
  Paper, 
  Stack, 
  Table, 
  TableBody, 
  TableCell, 
  tableCellClasses, 
  TableContainer, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Typography } from '@mui/material';
import { styled } from '@mui/system';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import MenuBar from '../../components/_products/MenuBar';
import { useOptionalFetch } from '../../hooks/optional/useOptional.query';
import { fCurrencyBr } from '../../utils/formatNumber';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function Optional() {
  const { data: optional } = useOptionalFetch()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Page title="Categoria">
      <Container>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Opcionais
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="criar"
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar novo
          </Button>
        </Stack>


        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MenuBar />
          </Grid>
          <Grid item xs={9}>
            <TableContainer component={Paper}>
              <Table stickyHeader sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Nome</StyledTableCell>
                    <StyledTableCell>Tipo</StyledTableCell>
                    <StyledTableCell>Preço</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {optional?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <StyledTableRow
                      hover
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">{row.name}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{row.choices_list}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{fCurrencyBr(row.price)}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="text"
                          component={RouterLink}
                          to={`editar/${row.id}`}
                        >
                          Editar
                        </Button>
                        <Button variant="text">Remover</Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      count={optional?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      colSpan={5}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

      </Container>
    </Page>
  )
}

export default Optional
