import { useQuery } from 'react-query';
import fetch from '../../services/fetch';

const getOrders = async ( status ) => {
  const { data } = await fetch(
    `${process.env.REACT_APP_API}/v1/orders/my-orders?status=${status}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
  return data
}

const getOrder = async ( id ) => {
  const { data } = await fetch(
    `${process.env.REACT_APP_API}/v1/orders/order?id=${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
  return data
}

const approveOrder = async ( orderId ) => {
  await fetch(
    `${process.env.REACT_APP_API}/v1/orders/accept-order?id=${orderId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
}

const onHoldOrder = async ( orderId ) => {
  await fetch(
    `${process.env.REACT_APP_API}/v1/orders/completed-order?id=${orderId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
}

export default function useOrdersFetch(status) {
  return useQuery(["orders", status], () => getOrders(status, {staleTime: 50000}))
}

export function useOrderFetch(id) {
  return useQuery(["orders"], () => getOrder(id))
}

export function useApprove(orderId) {
  return approveOrder(orderId)
  // return useQuery(["orders", orderId], () => approveOrder(orderId))
}

export function useOnHold(orderId) {
  return onHoldOrder(orderId)
}