import { useQuery } from 'react-query';
import fetch from '../../services/fetch';

const getUser = async () => {
  try {
    const  data  = await fetch(
      `${process.env.REACT_APP_API}/v1/user`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
        },
      },
    )

    return data
    
  } catch (error) {
    console.log(error.message)
  }
}

export function useUserFetch() {
  return useQuery(["user"], () => getUser(), {
    onSuccess: (data) => {
      // localStorage.setItem('@App:user', JSON.stringify(data.name))
    }
  })
}