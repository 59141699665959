import React from 'react'
import { 
  Button, 
  Container, 
  Icon, 
  Paper, 
  Stack, 
  Table, 
  TableBody, 
  TableCell, 
  tableCellClasses, 
  TableContainer, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Typography } from '@mui/material'
import { styled } from '@mui/system';
import Page from '../../components/Page'
import { useDeliveryMenFetch } from '../../hooks/deliveryMen/useDeliveryMen.query'
import { Link as RouterLink } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function DeliveryMen() {
  const { data: deliverymen } = useDeliveryMenFetch()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Page title="Entregador">
      <Container>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Entregador
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="criar"
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar novo
          </Button>
        </Stack>

        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Telefone</StyledTableCell>
                <StyledTableCell>Veiculo</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>{' '}</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {deliverymen?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <StyledTableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                  <StyledTableCell component="th" scope="row"> <img src={row.avatar} alt={row.first_name} /> </StyledTableCell>
                  <StyledTableCell component="th" scope="row"> {row.first_name} {row.last_name} </StyledTableCell>
                  <StyledTableCell component="th" scope="row"> {row.email} </StyledTableCell>
                  <StyledTableCell component="th" scope="row"> {row.phone} </StyledTableCell>
                  <StyledTableCell component="th" scope="row"> {row.vehicle} </StyledTableCell>
                  <StyledTableCell component="th" scope="row"> {row.status} </StyledTableCell>
                  <StyledTableCell align="right"> <Button variant="text" component={RouterLink} to={`editar/${row.id}`} > Editar </Button> <Button variant="text">Remover</Button> </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  count={deliverymen?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  colSpan={7}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

      </Container>
    </Page>
  )
}

export default DeliveryMen
