import { useQuery } from 'react-query';
import fetch from '../../services/fetch';

const getCategories = async () => {
  try {
    const { data } = await fetch(
      `${process.env.REACT_APP_API}/v1/category/my-categories`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
        },
      },
    )
    return data
  } catch (error) {
    console.log(error.message);
  }
  // const { data } = await fetch(
  //   `${process.env.REACT_APP_API}/category/my-categories`,
  //   {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
  //     },
  //   },
  // )
  // console.log(data)
  // return data
}

const postCategory = async (dataForm) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/category/create`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify(dataForm)
    },
  )

  return response
}

export function useCategoriesFetch() {
  return useQuery(["categories"], getCategories, {
    onSuccess: () => console.log('no problems found')
  })
}

export const useCategoryCreate = async (data) => {
  return postCategory(data)
}
