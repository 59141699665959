import React from 'react'
import Select from 'react-select';

function CustomSelect({ label, name, visible, ...rest }) {
  return (
    <div className={visible ? '' : 'hidden'}>
      {label && <><p>{label}</p><br /></>}
      <Select
        isSearchable
        name={name}
        {...rest}
      />
    </div>
  )
}

export default CustomSelect
