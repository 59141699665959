import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Page from '../../components/Page'
import DeliveryFee from '../../components/_tax/deliveryFee'
import MinimumOrder from '../../components/_tax/minimumOrder'

function Tax() {
  return (
    <Page title="Categoria">
      <Container>
        <Typography variant="h4" sx={{ mb: 0 }}>
          Taxas
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <MinimumOrder />
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <DeliveryFee />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default Tax
