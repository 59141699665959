import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import fetch from '../../services/fetch';

const getOptional = async () => {
  try {
    const { data } = await fetch(
      `${process.env.REACT_APP_API}/v1/optional`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
        },
      },
    )
    return data
  } catch (error) {
    console.log(error.message);
  }
}

const postOptional = async (dataForm) => {

  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/optional/create`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify(dataForm)
    },
  )
  toast.success(`${response.message}`, {
    position: toast.POSITION.TOP_CENTER
  });
  return response
}

export function useOptionalFetch() {
  return useQuery(["optional"], getOptional)
}

export const useOptionalCreate = async (data) => {
  let price = data.price.replace(/\D/g, '');
  return postOptional({ ...data, price })
}
