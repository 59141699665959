import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react'
import './styles.css'

const ToggleSwitch = ({ isOn, value, handleToggle, onColor, label, name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'checked',
        getValue: (ref) => ref.checked,
        setValue: (ref, value) => {
          ref.checked = Boolean(value);
        },
        clearValue: (ref) => {
          ref.checked = false;
        }
      });
    }
  }, [fieldName, registerField]);
  return (
    <>
      <label>{label}</label>
      <input
        name={name}
        id={name}
        ref={inputRef}
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        type="checkbox"
        {...rest}
      />
      <label
        className="react-switch-label"
        htmlFor={name}
        style={{ background: isOn && onColor }}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  )
}

export default ToggleSwitch
