import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import { useFormik } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Icon } from '@iconify/react';
import { IconButton, InputAdornment } from '@mui/material';
import {
  Link,
  Stack,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { useLogin } from '../../../hooks/login/useLogin.query'
import TextField from '../../Form/TextField';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { mutate, isLoading: saving } = useMutation(useLogin, {
    onSuccess: (data) => {
      console.log(data.status)
      if (data.status === 200) {
        setTimeout(
          () => navigate('/dashboard/app', { replace: true }),
          1000
        )
      }
    },
  })

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Nome de usuario é requerido'),
    password: Yup.string().required('Senha é requerida')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
  });

  const handleSubmit = async (dataLogin) => {
    mutate({ ...dataLogin })
  };

  const { errors, touched, values, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };



  return (
    <Form autoComplete="off" ref={formRef} onSubmit={handleSubmit}>
      <Stack spacing={3}>

        <TextField
          fullWidth
          name="username"
          label="Nome de Usuário"
          variant="outlined"
          type="text"
          autoComplete="username"
          required
          error={Boolean(touched.username && errors.username)}
          helperText={touched.username && errors.username}
        />

        <TextField
          name="password"
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          label="Senha"
          required
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
        />

      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel
          control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
          label="Remember me"
        />

        <Link component={RouterLink} variant="subtitle2" to="#">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={saving}
      >
        Login
      </LoadingButton>
    </Form>
  );
}
