import {  Card, Link, Skeleton, Typography } from '@mui/material';
import { alpha, styled } from '@mui/system';
import React from 'react'
import bugFilled from '@iconify/icons-ant-design/bug-filled';
import { Icon } from '@iconify/react';

import { useOptionalFetch } from '../../hooks/optional/useOptional.query'

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.light,
  backgroundColor: theme.palette.primary.darker,
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

const RootExists = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  width: '100%',
  padding: theme.spacing(2, 0),
  color: theme.palette.text.light,
  backgroundColor: theme.palette.primary.light,
}));

export const OptionalCount = () => {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={bugFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3"></Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Bug Reports
      </Typography>
    </RootStyle>
  )
}

export const OptionalExists = () => {
  const { isLoading, data: optional } = useOptionalFetch()
  return (
    <>
      {
        isLoading ? (
          <Skeleton variant="rect" height={80} />
        ) : (
          optional && optional?.length === 0 ? (
            <RootExists>
              <Link href="/dashboard/opcionais" color="inherit" underline="hover">
                <Typography variant="h4">Você ainda nao possui opcionais cadastrado </Typography>
                <Typography variant="subtitle2">
                  Cadastre alguns opcionais clicando aqui.
                </Typography>
              </Link>
            </RootExists>
          ) : ('')
        )}
    </>
  )
}
