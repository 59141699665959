import React from 'react';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import MainRoutes from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'react-toastify/dist/ReactToastify.css';
import './assets/global.css'

import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

const App = () => (
  <ThemeConfig>
    <GlobalStyles />
    <ToastContainer />
    <BaseOptionChartStyle />
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <MainRoutes />
    </QueryClientProvider>
  </ThemeConfig>
)

export default App;
