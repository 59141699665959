import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Page from '../../components/Page'
import TableOrder from '../../components/_orders/table'

function Orders() {
  return (
    <Page title="Orders">
      <Container>
        <Typography variant="h4" sx={{ mb: 0 }}>
          Orders
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableOrder />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default Orders
