import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {  useNavigate } from 'react-router-dom';

import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { useMutation } from 'react-query';
import { useSignup } from '../../../hooks/signup/useSignup.query';

const schema = yup.object({
  username: yup.string()
    .trim('O username não pode incluir espaços')
    .strict(true)
    .min(3, 'O username deve conter pelomenos 3 caracteres')
    .required('O username é requerido'),
  email: yup.string()
    .email('Deve ser um e-mail válido')
    .required('Email é requerido'),
  password: yup.string()
    .required("Por favor digite uma senha")
    .min(5, 'A senha deve conter pelomenos 5 caracteres'),
}).required();



function RegisterFormHook() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const { mutate, isLoading: saving } = useMutation(useSignup, {
    onSuccess: (data) => {
      console.log(data.status)
      if (data.status === 200) {
        setTimeout(
          () => navigate('/login', { replace: true }),
          1000
        )
      }
    },
  })

  const onSubmit = data => {
    mutate({ ...data })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          label="Nome de Usuário"
          variant="outlined"
          {...register("username")}
          error={Boolean(errors.username && errors.username)}
          helperText={errors.username ? errors.username?.message : 'Nome de Usuário não pode conter espaços'}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          {...register("email")}
          error={Boolean(errors.email && errors.email)}
          helperText={errors.email?.message}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          {...register("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
          helperText={errors.password?.message}
        />


        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={saving}
        >
          Register
        </LoadingButton>
      </Stack>
    </form>
  )
}

export default RegisterFormHook
