import { useQuery } from "react-query"
import { toast } from "react-toastify"
import fetch from "../../services/fetch"

const getMinimumOrder = async () => {
  const { data } = await fetch(
    `${process.env.REACT_APP_API}/v1/minimum-order`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
  return data
}

const putMinimumOrder = async (dataForm) => {
  console.log('dataForm: ', dataForm.minimumData.value)
  let value = dataForm.minimumData.value.replace(/\D/g, '');
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/minimum-order/update?id=${dataForm.id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify({
        ...dataForm.minimumData,
        value
      })
    },
  )
  toast.success(`${response.message}`, {
    position: toast.POSITION.TOP_CENTER
  });
  return response
}

export function useMinimumOrderFetch() {
  return useQuery(["minimum-order"], () => getMinimumOrder(), {retry: 6})
}

export const useMinimumOrderUpdate = async (data, id) => {
  return putMinimumOrder(data, id)
}
