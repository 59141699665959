import React from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { useUploadShopLogo } from '../../hooks/shop/useShopProfilePicture.query';

import { IKContext, IKUpload } from 'imagekitio-react'
import { toSlugify } from '../../utils/stringToSlug';
import { toast } from 'react-toastify';

function UploadProfilePicture({details}) {
  const queryClient = useQueryClient()

  // IMAGEKIT UPLOAD
  const publicKey = 'p3123rCxgLe6Qp6KDHUx1SqRDCw=';
  const urlEndpoint = 'https://ik.imagekit.io/500milhas';
  const authenticationEndpoint = `${process.env.REACT_APP_API}/v1/site/auth`
  
  // FOLDER UPLOAD
  const uf = toSlugify(details.state)
  const city = toSlugify(details.city)
  const shop = toSlugify(details.name)

  const folder = `${uf}/${city}/${shop}`

  const { mutateAsync: uploadShopPic } = useMutation(useUploadShopLogo, {
    onSettled: () => {
      queryClient.invalidateQueries('shop')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('shop')
    }
  })

  const onError = err => {
    console.log(err)
    toast.error(`${err}`, {
      position: toast.POSITION.TOP_CENTER
    });
  };
  const onSuccess = res => {
    // url
    const image = res.url;
    const dataShop = {
      image
    };
    toast.success('Imagem enviada com sucesso', {
      position: toast.POSITION.TOP_CENTER
    });
    console.log(res)
    uploadShopPic(dataShop)
  };

  return (
    <>
      <IKContext
        publicKey={publicKey}
        urlEndpoint={urlEndpoint}
        authenticationEndpoint={authenticationEndpoint}
        transformationPosition="path"
      >
        <IKUpload
          fileName="logo.jpg"
          onError={onError}
          onSuccess={onSuccess}
          useUniqueFileName={false}
          folder={folder}
        />
      </IKContext>
    </>
  )
}

export default UploadProfilePicture
