import { Button, Container, Stack, Typography } from '@mui/material'
import { Form } from '@unform/web'
import React, { useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import CustomSelect from '../../components/Form/Select'
import TextField from '../../components/Form/TextField'
import Page from '../../components/Page'
import { useCategoriesFetch } from '../../hooks/categories/useCategories.query'
import { useOptionalCreate } from '../../hooks/optional/useOptional.query'

function OptionalCreate() {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const [categoriesList, setCategoriesList] = useState(['']);

  const { isLoading: loadingCategories, data: categories } = useCategoriesFetch()
  const { mutateAsync, isLoading: saving } = useMutation(useOptionalCreate, {
    onSettled: () => {
      queryClient.invalidateQueries('optional')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('optional')
    }
  })

  const optionsCategory = loadingCategories
    ? 'carregando...'
    : categories &&
    categories.length > 0 &&
    categories.map((category) => ({
      value: category.title.replace(' ', '-'),
      label: category.title
    }));
  const categoriesOnChange = (opt, ctx) => {
    const { label } = opt;
    setCategoriesList(label);
  };

  async function handleSubmit(dataForm) {
    const choices_list = categoriesList;
    const dataOptional = {
      choices_list,
      ...dataForm
    }
    await mutateAsync(dataOptional)
    navigate('/dashboard/opcionais', { replace: true });
  }


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: 'var(--color-input-placeholder)',
      color: state.isSelected
        ? 'var(--color-primary)'
        : 'var(--color-input-placeholder)',
      backgroundColor: state.isSelected
        ? 'var(--color-background-dark)'
        : 'var(--color-input-background)'
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      backgroundColor: 'var(--color-input-background)',
      marginBottom: '10px',
      border: '1px solid var(--color-input-border)'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
        color: 'var(--color-input-placeholder)'
      };
    }
  };

  if (saving) {
    return (
      <Typography variant="h4" gutterBottom>
        Salvando
      </Typography>
    )
  }
  return (
    <Page title="Criar Produtos">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Cadastro de Produto
          </Typography>
        </Stack>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextField name="name" type="text" label="Nome do opcional" fullWidth required />
          <TextField label="Valor" type="text" name="price" fullWidth required />
          <CustomSelect
            label='Categoria'
            isSearchable
            name="choices_list"
            options={optionsCategory}
            onChange={categoriesOnChange}
            styles={customStyles}
            visible={true}
            required
          />

          <hr className="order" />
          <Button type="submit" variant="outlined" size="large" > Salvar </Button>

        </Form>
      </Container>
    </Page>
  )
}

export default OptionalCreate
