import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import fetch from '../../services/fetch';

const getDeliveryFee = async () => {
  const { data } = await fetch(
    `${process.env.REACT_APP_API}/v1/delivery-fee`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
  return data
}

const putDeliveryFee = async (dataForm) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/delivery-fee/update?id=${dataForm.id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify(dataForm.deliveryFeeData)
    },
  )
  toast.success(`${response.message}`, {
    position: toast.POSITION.TOP_CENTER
  });
  return response
}

export function useDeliveryFeeFetch() {
  return useQuery(["delivery-fee"], getDeliveryFee)
}

export const useDeliveryFeeUpdate = async (data, id) => {
  return putDeliveryFee(data, id)
}
