import React, { useState } from 'react'
import { Card, CardActions, CardMedia, Drawer, Typography } from '@mui/material'
import { styled } from '@mui/system';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import UploadProfilePicture from '../../_shop/uploadProfilePicture';
import { useShopFetch } from '../../../hooks/shop/useShop.query';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(0, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
  height: 250,
}));
const CardContentStyled = styled('div')(({ theme }) => ({
  padding: 0
}));

const CardProfilePicture = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  position: 'absolute',
  marginTop: -100,
  marginBottom: -80,
  marginLeft: 20,
  width: 180,
  height: 180,
  '& .change-picture': {
    transition: '0.5s all',
    marginTop: -45,
    width: '100%',
    position: 'absolute',
    cursor: 'pointer'
  },
  '&:hover': {
    '& .change-picture': {
      marginTop: -85,
    }
  }
}));

const CardActionsStyled = styled(CardActions)(({ theme }) => ({
  background: theme.palette.primary.dark,
  textAlign: 'center',
}))

const CardProfileContentStyled = styled('div')(({ theme }) => ({
  padding: 0
}));

const ContentStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  color: theme.palette.text.light,
  padding: theme.spacing(12, 10),
  '&::-webkit-scrollbar': {
    width: '3px',
    height: '3px',
  },
  '&::-webkit-scrollbar-track': {
    background: ''
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
  }
}));
const Close = styled('div')(({ theme }) => ({
  position: 'inherit',
  margin: '10px 0px 0 -65px',
  background: theme.palette.primary.dark,
  borderRadius: '10px 0 0 10px',
  padding: '5px 10px',
  cursor: 'pointer',
  color: theme.palette.text.light,
}));


function Profile() {
  const { data: shop } = useShopFetch()
  const [modalChangeProfilePicture, setModalChangeProfilePicture] = useState(false)

  const handleOpenChangePicture = () => {
    setModalChangeProfilePicture(true)
  }
  const handleClose = () => {
    setModalChangeProfilePicture(false)
  };

  return (
    <>
      <RootStyle sx={{ minWidth: 275 }}>
        <CardContentStyled>
          <CardMedia
            component="img"
            height="250"
            image="https://images.unsplash.com/photo-1637735492554-26e48001fefc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1025&q=80"
            alt="green iguana"
          />
        </CardContentStyled>
        <CardActions>

        </CardActions>
      </RootStyle>

      <CardProfilePicture>
        <CardProfileContentStyled>
          <CardMedia
            component="img"
            height="220"
            image={shop?.image}
            alt="green iguana"
          />
        </CardProfileContentStyled>
        <CardActionsStyled className="change-picture" onClick={handleOpenChangePicture}>
          <Icon icon={plusFill} />
        </CardActionsStyled>
      </CardProfilePicture>


      <Drawer
        anchor='right'
        variant='temporary'
        open={modalChangeProfilePicture}
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', maxWidth: 650, zIndex: (theme) => theme.zIndex.drawer + 1 },
        }}
        onClose={handleClose}
      >
        <Close role="presentation" onClick={handleClose} > CLOSE </Close>
        <ContentStyle>
          <Typography variant="h6" component="h2" sx={{ textAlign: 'center' }}>
            Envie a logo de sua loja
          </Typography>
          <UploadProfilePicture details={shop} />
        </ContentStyle>
      </Drawer>

    </>
  )
}

export default Profile
