import React, { useRef, useEffect } from 'react'
import { Card, CardHeader, Grid, Skeleton } from '@mui/material'
import { alpha, Box, styled } from '@mui/system';
import { Form } from '@unform/web';
import { useMutation, useQueryClient } from 'react-query';
import { useMinimumOrderFetch, useMinimumOrderUpdate } from '../../hooks/minimumOrder/useMinimumOrder.query'
import { LoadingButton } from '@mui/lab';
import TextField from '../Form/TextField';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(0, 0),
  margin: theme.spacing(4, 0),
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.grey[800], 0.72)
}));


function MinimumOrder() {
  const formRef = useRef(null);
  const queryClient = useQueryClient()
  const { isLoading, isError, data: minimumOrderData } = useMinimumOrderFetch()

  const { mutateAsync, isLoading: saving } = useMutation(useMinimumOrderUpdate, {
    onSettled: () => {
      queryClient.invalidateQueries('minimum-order')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('minimum-order')
    }
  })

  useEffect(() => {
    formRef.current?.setData(minimumOrderData);
  }, [minimumOrderData])
  
  const handleSubmit = async (minimumData) => {
    const id = minimumOrderData?.id;
    await mutateAsync({ minimumData, id })
  };

  if (isLoading) return (
    <RootStyle>
      <Skeleton variant="rect" height={80} />
    </RootStyle>
  )
  if (isError) return (
    <RootStyle>
      <CardHeader title="Erro" />
    </RootStyle>
  )

  return (

    <RootStyle>
      <CardHeader title="Pedido minimo" />
      <Box sx={{ pt: 2, pb: 2, px: 3 }} >
        <Grid container >
          <Grid item xs={12}>
            <Form ref={formRef} onSubmit={handleSubmit}  >
              <TextField label="Valor" type="text" name="value" fullWidth />
              <LoadingButton
                loading={saving ? true : false}
                type="submit"
                variant="outlined"
                size="large"
                style={{ width: '100%', height: 58, marginTop: 10 }}>
                Salvar
              </LoadingButton>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </RootStyle>
  )
}

export default MinimumOrder
