import React, { useState } from 'react'
import useOrdersFetch from '../../hooks/orders/useOrders.query'
import { 
  Drawer, 
  Grid, 
  Paper, 
  Skeleton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography } from '@mui/material';
import { styled } from '@mui/system';
import { TableCellStatus, TableRowStyled } from './styles'
import DrawerDetail from './drawerDetail';
import { fCurrencyBr } from '../../utils/formatNumber';

const Close = styled('div')(({ theme }) => ({
  position: 'inherit',
  margin: '10px 0px 0 -65px',
  background: theme.palette.primary.dark,
  borderRadius: '10px 0 0 10px',
  padding: '5px 10px',
  cursor: 'pointer',
  color: theme.palette.text.light,
}));


function TableOrder() {
  const [orderType] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false)
  const [idOrder, setIdOrder] = useState(null)
  const { isLoading, data: orders } = useOrdersFetch(orderType)

  const handleClickOrder = (e) => {
    setIdOrder(e)
    setOpenDrawer(true)
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Valor Total</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                isLoading && (
                  <TableRowStyled>
                    <TableCell scope="row" colSpan={4}>
                      <Skeleton variant="rect" width='100%' height={85} />
                    </TableCell>
                  </TableRowStyled>
                )
              }
              {orders && orders.map((order) => (
                <TableRowStyled key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => handleClickOrder(order.id)}>
                  <TableCell component="th" scope="row">
                    {
                      JSON.parse(order.billing).map((bill, index) => (
                        <Typography key={index} variant="body1" sx={{ mb: 0 }}>
                          {bill.first_name} {bill.last_name}
                        </Typography>
                      ))
                    }
                  </TableCell>
                  <TableCell>
                    {
                      JSON.parse(order.items).map((item, index) => (
                        <Typography key={index} variant="body1" sx={{ mb: 0 }}>
                          {item.name} - {item.quantity}
                        </Typography>
                      ))
                    }
                  </TableCell>
                  <TableCell>{fCurrencyBr(order.total)}</TableCell>
                  <TableCellStatus className={`status ${order.status}`} align="center">{order.status}</TableCellStatus>
                </TableRowStyled>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Drawer
        anchor='right'
        variant='temporary'
        open={openDrawer}
        sx={{ px: 5, minWidth: 400 }}
        onClose={handleDrawerClose}
      >
        <Close role="presentation" onClick={handleDrawerClose} > CLOSE </Close>
        <DrawerDetail id={idOrder} />
      </Drawer>

    </Grid>
  )
}

export default TableOrder
