import { Button, Container, Stack, Typography } from '@mui/material';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import CustomSelect from '../../components/Form/Select';
import TextField from '../../components/Form/TextField';
import ToggleSwitch from '../../components/Form/ToggleSwitch';
import Page from '../../components/Page';
import { useOptionalFetch } from '../../hooks/optional/useOptional.query';
import { useProductId } from '../../hooks/products/useProducts.query';

function ProductsEdit() {
  const { id } = useParams();
  const formRef = useRef(null);
  const [isOptional, setIsOptional] = useState(false);
  const [setInputOptionalList] = useState([{ optional: '' }]);

  const { data: product } = useProductId(id)
  const { isLoading: loadingOptional, data: optional } = useOptionalFetch()



  useEffect(() => {
    formRef.current.setData(product);
  }, [product]);
  
  const handleChangeOptional = (value) => {
    setInputOptionalList(value);
  };
  

  const optionsOptional = loadingOptional
    ? 'carregando...'
    : optional &&
    optional.length > 0 &&
    optional.map((category) => ({
      value: category.id,
      label: category.name
    }));


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: 'var(--color-input-placeholder)',
      color: state.isSelected
        ? 'var(--color-primary)'
        : 'var(--color-input-placeholder)',
      backgroundColor: state.isSelected
        ? 'var(--color-background-dark)'
        : 'var(--color-input-background)'
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      backgroundColor: 'var(--color-input-background)',
      marginBottom: '10px',
      border: '1px solid var(--color-input-border)'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
        color: 'var(--color-input-placeholder)'
      };
    }
  };


  async function handleSubmit(productData) {
    // await mutateAsync({ ...productData, id })
  }
  return (
    <Page title="Produtos">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Editar: {product?.name}
          </Typography>
        </Stack>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <ToggleSwitch
            name="isVegetarian"
            label="É Vegetariano ?"
            onColor="#ea7c69"
            isOn={!!product?.isVegetarian}
          />

          <TextField
            name="cuisine"
            type="text"
            label="Tipo de cozinha"
            fullWidth
          />
          <TextField
            name="name"
            type="text"
            label="Nome do prato" required
            fullWidth
          />
          <TextField
            name="description"
            type="text"
            label="Descrição do prato" required
            fullWidth
          />
          <TextField
            name="price"
            type='text'
            label="Preço"
            fullWidth
          />
          <TextField
            name="regular_price"
            type='text'
            label="Preço Normal"
            fullWidth
          />
          <TextField
            name="for_peoples"
            type='text'
            label="Serve Quantas Pessoas"
            fullWidth
          />
          <TextField
            name="ingredients"
            type="text"
            label="ingredients"
            fullWidth
          />

          <ToggleSwitch
            label="Tem Opcionais?"
            name="isOptional"
            value={isOptional}
            onColor="#ea7c69"
            handleToggle={() => setIsOptional(!!product?.with_optional)}
            isOn={!!product?.with_optional}
          />

          <CustomSelect
            isMulti
            label='Opcionais'
            name="optional"
            options={optionsOptional}
            onChange={handleChangeOptional}
            styles={customStyles}
            visible={!!product?.with_optional}
          />

          <Button type="submit" width="300px" variant="outlined" size="large">
            Salvar
          </Button>
        </Form>
      </Container>
    </Page>

  )
}

export default ProductsEdit
