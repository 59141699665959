import React, { useRef } from 'react'
import { alpha, styled } from '@mui/material/styles';
import { Button, Card, CardHeader, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Form } from '@unform/web';
import { useMutation, useQueryClient } from 'react-query';
import { useCategoryCreate } from '../../hooks/categories/useCategories.query';
import { toast } from 'react-toastify';
import TextField from '../../components/Form/TextField';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(0, 0),
  margin: theme.spacing(4, 0),
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.grey[800], 0.72)
}));

function CategoryCreate() {
  const formRef = useRef(null);
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading: saving } = useMutation(useCategoryCreate, {
    onSettled: () => {
      queryClient.invalidateQueries('categories')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('categories')
      toast.success("Success Notification !", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  })
  const handleSubmit = async (categoriesData) => {
    await mutateAsync({ ...categoriesData })
  };

  if (saving) {
    return (
      <Typography variant="h4" gutterBottom>
        Salvando
      </Typography>
    )
  }
  return (
    <RootStyle>
      <CardHeader title="Criar nova categoria" />
      <Box sx={{ pt: 2, pb: 2, px: 3 }} >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Grid container >
            <Grid item xs={9} sx={{ mt: -3 }}>
              <TextField name="title" type="text" label="Categoria" fullWidth required/>
            </Grid>
            <Grid item xs={3}>
              <Button type="submit" variant="outlined" size="large" style={{ width: '80%', marginLeft: 45, height: 58, marginTop: -4 }}> Salvar </Button>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </RootStyle>
  )
}

export default CategoryCreate
