import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, Link, Container, Typography, Card } from '@mui/material';
import Page from '../../components/Page';
import AuthSocial from '../../components/authentication/AuthSocial';
import AuthLayout from '../../layout/AuthLayout';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login'
import { Box } from '@mui/system';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.text.light
}));
const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.text.light
}));
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  color: theme.palette.text.light
}));

function Login() {
  return (
    <RootStyle title="Login">
      <AuthLayout>
        Voce não possui conta? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/cadastrar">
          Cadastre-se
        </Link>
      </AuthLayout>

      <MHidden width="mdDown" >
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5, textAlign: "center"}}>
            Bem-vindo de Volta
          </Typography>
          <Box sx={{ px: 6}}>
            <img src="/static/illustrations/illustration_login.svg" alt="login" />
          </Box>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Login Painel Restaurante
            </Typography>
          </Stack>
          <AuthSocial />

          <LoginForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3, color: "red" }}>
              Voce não possui conta? &nbsp;
              <Link variant="subtitle2" component={RouterLink} to="cadastrar">
                Cadastre-se
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>

    </RootStyle>
  )
}

export default Login
