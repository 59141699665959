import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import fetch from '../../services/fetch';

const getDeliveryMen = async () => {
  const { data } = await fetch(
    `${process.env.REACT_APP_API}/v1/deliveryman/my-deliveries`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
    },
  )
  return data
}

const postDeliveryMen = async (dataForm) => {
  console.log(dataForm)
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/deliveryman/create`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify(dataForm)
    },
  )

  toast.success("Success Notification !", {
    position: toast.POSITION.TOP_CENTER
  });

  return response
}

export function useDeliveryMenFetch() {
  return useQuery(["delivery-men"], getDeliveryMen)
}

export const useDeliveryMenCreate = async (data) => {
  return postDeliveryMen(data)
}
