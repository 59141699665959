import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import Page from '../../components/Page'
import { Form } from '@unform/web'
import { useMutation, useQueryClient } from 'react-query'
import { useShopCreate } from '../../hooks/shop/useShop.query'
import fetch from '../../services/fetch'
import TextField from '../../components/Form/TextField'
import { useNavigate } from 'react-router'
import { toSlugify } from '../../utils/stringToSlug'
import { useUserFetch } from '../../hooks/user/useUser.query'
import { toast } from 'react-toastify'

function ShopCreate() {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState();
  const [stateIsVegetarian, setStateIsVegetarian] = useState(false);

  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [district, setDistrict] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')


  const { data: user } = useUserFetch()
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading: saving } = useMutation(useShopCreate, {
    onSettled: () => {
      queryClient.invalidateQueries('shop')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('shop')
      // localStorage.setItem('shop', 'created')
    }
  })

  useEffect(() => {
    setUserEmail(user?.email)
  }, [user?.email])


  const handleCep = async (e) => {
    await fetch(`https://brasilapi.com.br/api/cep/v2/${e.target.value}`).then((response) => {
      setAddress(response?.street)
      setState(response?.state)
      setCity(response?.city)
      setDistrict(response?.neighborhood)
      setLat(response?.location.coordinates.latitude)
      setLng(response?.location.coordinates.longitude)
    })
      .catch((error) => {
        toast.error('Por favor verifique o CEP', {
          position: toast.POSITION.TOP_CENTER
        });
      })
  };


  const handleSubmit = async (dataForm) => {
    const form = dataForm;
    const uf = toSlugify(dataForm.state)
    const city = toSlugify(dataForm.city)
    const shop = toSlugify(dataForm.name)

    const vegetarian = stateIsVegetarian;
    const link = `${process.env.LINK_LOJA}/${uf}/${city}/${shop}`

    const dataShop = {
      ...form,
      ...vegetarian,
      link
    };
    console.log(dataShop);
    await mutateAsync({ ...dataShop })
    setTimeout(
      () => navigate('/dashboard/loja', { replace: true }),
      2000
    )
  }

  const handleChange = (event) => {
    setStateIsVegetarian({
      ...stateIsVegetarian,
      [event.target.name]: event.target.checked
    });
  };

  if (saving) {
    return (
      <Typography variant="h4" gutterBottom>
        Salvando
      </Typography>
    )
  }


  return (
    <Page title="Criar Loja">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Dados da loja
          </Typography>
        </Stack>

        <Form ref={formRef} onSubmit={handleSubmit} >
          <FormControlLabel
            control={<Switch name="isVegetarian" onChange={handleChange} />}
            label="Seu restaurante é vegetariano?"
          />

          <TextField
            name="name"
            type="text"
            label="Nome do restaurante"
            fullWidth
          />

          <TextField
            name="phone"
            type="text"
            label="Telefone"
            fullWidth
            mask="(99) 9999-9999?"
            formatChars={{ 9: '[0-9]', t: '[0-9-]', '?': '[0-9 ]' }}
            maskChar={null}
          />

          <TextField
            name="cuisine"
            type="text"
            label="Tipo de cozinha"
            fullWidth
          />
          <TextField
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
            name="email"
            type="text"
            label="Email"
            fullWidth
          />
          {/* <TextField
            name="link_generated"
            type="text"
            label="Link Loja"
            fullWidth
          /> */}
          <TextField
            name="postal_code"
            type="text"
            label="Cep" onBlur={handleCep}
            fullWidth
          />
          <TextField
            value={address}
            onChange={e => setAddress(e.target.value)}
            name="address"
            type="text"
            fullWidth
            label="Endereço"
            sx={{ my: 3 }}
          />

          <TextField
            name="number"
            type="text"
            fullWidth
            label="Numero"
            sx={{ my: 3 }}
          />

          <TextField
            value={district}
            onChange={e => setDistrict(e.target.value)}
            name="district"
            type="text"
            fullWidth
            label="Bairro"
            sx={{ my: 3 }}
          />

          <TextField
            value={city}
            onChange={e => setCity(e.target.value)}
            name="city"
            type="text"
            fullWidth
            label="Cidade"
            sx={{ my: 3 }}
          />

          <TextField
            value={state}
            onChange={e => setState(e.target.value)}
            name="state"
            type="text"
            fullWidth
            label="Estado"
            sx={{ my: 3 }}
          />

          <TextField
            value={lat}
            onChange={e => setLat(e.target.value)}
            name="latitude"
            type="text"
            label="Latitude"
            sx={{ my: 3, mr: 3 }}
          />

          <TextField
            value={lng}
            onChange={e => setLng(e.target.value)}
            name="longitude"
            type="text"
            label="Longitude"
            sx={{ my: 3 }}
          />

          <hr className="order" />
          <Button type="submit" variant="outlined" size="large" > Salvar </Button>
        </Form>

      </Container>
    </Page>
  )
}

export default ShopCreate
