import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import dollarCircleOutlined from '@iconify/icons-ant-design/dollar-circle-outlined';
import shoppingCartOutlined from '@iconify/icons-ant-design/shopping-cart-outlined';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon(shoppingCartOutlined)
  },
  {
    title: 'produtos',
    path: '/dashboard/produtos',
    icon: getIcon(shoppingBagFill),
    type: 'link',
    badgeType: 'primary',
    active: false
  },
  {
    title: 'loja',
    path: '/dashboard/loja',
    icon: getIcon(lockFill),
    active: false,
  },

  {
    title: 'Taxas',
    icon: getIcon(dollarCircleOutlined),
    type: 'link',
    active: false,
    path: '/dashboard/taxas',
  },
  {
    title: 'Entregador',
    icon: getIcon('mdi-racing-helmet'),
    type: 'link',
    active: false,
    path: '/dashboard/entregador',
  },

];

export default sidebarConfig;
