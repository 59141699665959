import React from 'react'
import { Container, Grid, Stack, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Page from '../../components/Page'
import { styled } from '@mui/material/styles';
import useProductsFetch from '../../hooks/products/useProducts.query'
import { Button } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import MenuBar from '../../components/_products/MenuBar';
import { OptionalExists } from '../../components/_products/optional';
import { CategoriesExists } from '../../components/_products/categories';
import { fCurrencyBr } from '../../utils/formatNumber'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Products() {
  const { data: products } = useProductsFetch()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Page title="Produtos">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Produtos
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="criar"
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar novo
          </Button>
        </Stack>

        <Stack direction="column" alignItems="center" justifyContent="space-between" mb={5} spacing={2}>
          <CategoriesExists />
          <OptionalExists />
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={3}>

            <MenuBar />

          </Grid>

          <Grid item xs={9}>

            <TableContainer component={Paper}>
              <Table stickyHeader sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Nome</StyledTableCell>
                    <StyledTableCell align="right">Descrição</StyledTableCell>
                    <StyledTableCell align="right">Preço</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {products?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <StyledTableRow
                      hover
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.description}</StyledTableCell>
                      <StyledTableCell align="right">{fCurrencyBr(row.price)}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="text"
                          component={RouterLink}
                          to={`editar/${row.id}`}
                        >
                          Editar
                        </Button>
                        <Button variant="text">Remover</Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      count={products?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      colSpan={5}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default Products
