import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web';
import TextField from '../../components/Form/TextField';
import { Container, Divider } from '@mui/material';

import { useMutation, useQueryClient } from 'react-query'
import { useShopFetch, useShopUpdate } from '../../hooks/shop/useShop.query'
import fetch from '../../services/fetch';
import Page from '../../components/Page';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';

function ShopEdit2() {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const { data: shopData } = useShopFetch()
  const [initialData, setInitialData] = useState({});

  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [district, setDistrict] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')

  const { mutateAsync, isLoading: saving } = useMutation(useShopUpdate, {
    onSettled: () => {
      queryClient.invalidateQueries('shop')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('shop')
      // localStorage.setItem('shop', 'created')
    }
  })

  useEffect(() => {
    formRef.current.setData(shopData);
    setAddress(shopData?.address)
    setState(shopData?.state)
    setCity(shopData?.city)
    setDistrict(shopData?.district)
    setLat(shopData?.latitude)
    setLng(shopData?.longitude)
  }, [shopData]);

  const handleSubmit = async (dataForm) => {
    const form = dataForm;
    // const vegetarian = stateIsVegetarian;

    const dataShop = {
      ...form,
      // ...vegetarian
    };
    await mutateAsync({ ...dataShop })
    setTimeout(
      () => navigate('/dashboard/loja', { replace: true }),
      2000
    )
  }


  const handleCep = async (e) => {
    await fetch(`https://brasilapi.com.br/api/cep/v2/${e.target.value}`).then((response) => {
      setInitialData({
        state: response.state,
        city: response.city,
        district: response.neighborhood,
        address: response.street,
        latitude: response.location.coordinates.latitude,
        longitude: response.location.coordinates.longitude
      })
      setAddress(response.street)
      setState(response.state)
      setCity(response.city)
      setDistrict(response.neighborhood)
      setLat(response.location.coordinates.latitude)
      setLng(response.location.coordinates.longitude)
    }
    )
      .catch((err) => console.log({ 'error': err.message }))
  };

  return (
    <Page title="Editar Loja">
      <Container maxWidth="xl">


        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>

          <TextField
            name="link_generated"
            type="text"
            placeholder="Link Loja"
          />


          <TextField
            name="name"
            type="text"
            fullWidth
            label="Nome do restaurante"
            sx={{ my: 3 }}
          />

          <TextField
            name="phone"
            type="text"
            label='Telefone'
            fullWidth
          // mask="(99) 9999-9999?"
          // formatChars={{ 9: '[0-9]', t: '[0-9-]', '?': '[0-9 ]' }}
          // maskChar={null}
          />

          <TextField
            name="cuisine"
            type="text"
            fullWidth
            label="Tipo de cozinha"
            sx={{ my: 3 }}
          />

          <TextField
            name="email"
            type="text"
            fullWidth
            label="Email"
            sx={{ my: 3 }}
          />

          <TextField
            name="postal_code"
            type="text"
            onBlur={handleCep}
            fullWidth
            label="CEP"
            sx={{ my: 3 }}
          />
          <TextField
            value={address}
            onChange={e => setAddress(e.target.value)}
            name="address"
            type="text"
            fullWidth
            label="Endereço"
            sx={{ my: 3 }}
          />

          <TextField
            name="number"
            type="text"
            fullWidth
            label="Numero"
            sx={{ my: 3 }}
          />

          <TextField
            value={district}
            onChange={e => setDistrict(e.target.value)}
            name="district"
            type="text"
            fullWidth
            label="Bairro"
            sx={{ my: 3 }}
          />

          <TextField
            value={city}
            onChange={e => setCity(e.target.value)}
            name="city"
            type="text"
            fullWidth
            label="Cidade"
            sx={{ my: 3 }}
          />

          <TextField
            value={state}
            onChange={e => setState(e.target.value)}
            name="state"
            type="text"
            fullWidth
            label="Estado"
            sx={{ my: 3 }}
          />

          <TextField
            value={lat}
            onChange={e => setLat(e.target.value)}
            name="latitude"
            type="text"
            label="Latitude"
            sx={{ my: 3, mr: 3 }}
          />

          <TextField
            value={lng}
            onChange={e => setLng(e.target.value)}
            name="longitude"
            type="text"
            label="Longitude"
            sx={{ my: 3 }}
          />

          <Divider />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={saving}
          >
            Salvar
          </LoadingButton>
        </Form>

      </Container>
    </Page>
  )
}

export default ShopEdit2
