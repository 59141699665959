import fetch from '../../services/fetch';

const putShopPicture = async (dataForm) => {
  const formData = new FormData()
  console.log('image', dataForm)
  formData.append('image', dataForm)


  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/shop/upload-shop-logo`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        Authorization: localStorage.getItem(process.env.REACT_APP_NAMED_TOKEN)
      },
      body: JSON.stringify(dataForm)
    },
  )

  return response
}



export const useUploadShopLogo = async (data) => {
  return putShopPicture(data)
}
