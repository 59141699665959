import React, { useRef } from 'react'
import { Button, Container, Stack, Typography } from '@mui/material'
import Page from '../../components/Page'
import { useMutation, useQueryClient } from 'react-query';
import { useDeliveryMenCreate } from '../../hooks/deliveryMen/useDeliveryMen.query';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router';
import TextField from '../../components/Form/TextField';


function DeliveryMenCreate() {
  const formRef = useRef(null);
  const queryClient = useQueryClient()
  const navigate = useNavigate();

  const { mutateAsync, isLoading: saving } = useMutation(useDeliveryMenCreate, {
    onSettled: () => {
      queryClient.invalidateQueries('delivery-men')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('delivery-men')
      navigate('/dashboard/entregador', { replace: true })
    }
  })

  const handleSubmit = async (deliverymen) => {
    await mutateAsync({ ...deliverymen })
  };

  if (saving) {
    return (
      <Typography variant="h4" gutterBottom>
        Salvando
      </Typography>
    )
  }
  return (
    <Page title="Entregador">
      <Container>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Criar Entregador
          </Typography>
        </Stack>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextField name="first_name" type='text' label="Nome" fullWidth/>
          <TextField name="last_name" type='text' label="Sobrenome" fullWidth/>
          <TextField name="email" type='text' label="email" fullWidth/>
          <TextField name="phone" type='text' label="Telefone" fullWidth/>
          <TextField name="avatar" type='text' label="avatar" fullWidth/>
          <TextField name="vehicle" type='text' label="Veiculo" fullWidth/>
          <hr className="order" />
          <Button type="submit" variant="outlined" size="large" > Salvar </Button>
        </Form>
      </Container>
    </Page>
  )
}

export default DeliveryMenCreate
