import { Navigate, useRoutes } from 'react-router-dom';
import Dashboard from '../pages/dashboard';
import DashboardLayout from '../layout/dashboard';
import User from '../pages/user';
import Products from '../pages/products';
import ProductsCreate from '../pages/products/create';
import Login from '../pages/login';
import ProductsEdit from '../pages/products/edit';
import Category from '../pages/category';
import Optional from '../pages/optional';
import OptionalCreate from '../pages/optional/create';
import OptionalEdit from '../pages/optional/edit';
import Shop from '../pages/shop';
import ShopCreate from '../pages/shop/create';
import ShopEdit2 from '../pages/shop/editNew';
import Tax from '../pages/tax';
import Orders from '../pages/orders';
import DeliveryMen from '../pages/delivery_men';
import DeliveryMenCreate from '../pages/delivery_men/create';
import { isAuthenticated } from '../services/auth'
import LogoOnlyLayout from '../layout/LogoOnlyLayout';
import Signup from '../pages/signup';

export default function Router() {

  return useRoutes([
    {
      path: '/dashboard',
      element: isAuthenticated() ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <Dashboard /> },
        { path: 'user', element: <User /> },

        {
          path: 'produtos',
          children: [
            { path: '', element: <Products /> },
            { path: 'criar', element: <ProductsCreate /> },
            { path: 'editar/:id', element: <ProductsEdit /> }
          ]
        },
        {
          path: 'opcionais',
          children: [
            { path: '', element: <Optional /> },
            { path: 'criar', element: <OptionalCreate /> },
            { path: 'editar/:id', element: <OptionalEdit /> },
          ]
        },
        {
          path: 'loja',
          children: [
            { path: '', element: <Shop /> },
            { path: 'criar', element: <ShopCreate /> },
            { path: 'editar', element: <ShopEdit2 /> },
            // { path: 'editar', element: <ShopEdit /> },
          ]
        },
        {
          path: 'entregador',
          children: [
            { path: '', element: <DeliveryMen /> },
            { path: 'criar', element: <DeliveryMenCreate /> },
          ]
        },
        { path: 'categorias', element: <Category /> },
        { path: 'taxas', element: <Tax /> },
        { path: 'orders', element: <Orders /> },

      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'cadastrar', element: <Signup /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}