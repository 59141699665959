import { toast } from 'react-toastify';
import fetch from '../../services/fetch';

const Login = async (dataForm) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/site/login`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataForm)
    },
  )

  if (response.status === 200) {
    localStorage.setItem(process.env.REACT_APP_NAMED_TOKEN, response.token);
  }

  if (response.status === 401) {
    toast.warning(`${response.message}`, {
      position: toast.POSITION.TOP_CENTER
    });
    return response
  }

  toast.success(`${response.message}`, {
    position: toast.POSITION.TOP_CENTER
  });

  return response
}


export const useLogin = async (data) => {
  return Login(data)
}
