import React, { Fragment } from 'react'
import { Button, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useApprove, useOnHold, useOrderFetch } from '../../hooks/orders/useOrders.query'
import { useMutation, useQueryClient } from 'react-query';
import { alpha } from '@material-ui/core';

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '3px',
    height: '3px',
  },
  '&::-webkit-scrollbar-track': {
    background: ''
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
  }
}));

const ButtonAccept = styled(Button)(({ theme }) => ({
  // background: theme.palette.primary.main,
}));

const CardStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.grey[800], 0.72)
}));
const CardContentStyle = styled(CardContent)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(0, 3),
}));
const CardHeaderStyle = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(1, 3),

}));


function DrawerDetail({ id }) {
  const queryClient = useQueryClient()
  const { isLoading, data: order } = useOrderFetch(id)

  const { mutateAsync: approveOrder } = useMutation(useApprove, {
    onSettled: () => {
      queryClient.invalidateQueries('orders')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('orders')
    }
  })
  const { mutateAsync: onHoldOrder } = useMutation(useOnHold, {
    onSettled: () => {
      queryClient.invalidateQueries('orders')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('orders')
    }
  })

  const handleApprove = async (id) => {
    await approveOrder(id)
  }

  const handleOnHold = async (id) => {
    await onHoldOrder(id)
  };

  if (isLoading) {
    return (
      <RootStyle sx={{ width: 500, px: 2.5 }} role="presentation" >
        <Skeleton variant="rect" width='100%' height={85} />
      </RootStyle>
    )
  }

  return (
    <RootStyle
      sx={{ width: 500, px: 2.5 }}
      role="presentation"
    >
      <Typography variant="h4" sx={{ py: 2, color: '#fefefe', textAlign: 'center' }}>
        Comanda de pedido #{order?.number_order}
      </Typography>

      <Divider />

      <Box sx={{ py: 2 }}>
        {
          order?.status === 'pending' && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ButtonAccept variant="contained" size="large" color="success" sx={{ width: '100%' }} onClick={() => handleApprove(id)} role="presentation">Aceitar</ButtonAccept>
              </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" size="large" color="warning" sx={{ width: '100%' }}>Recusar</Button>
              </Grid>
            </Grid>
          )
        }

        {
          order?.status === 'processing' && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button variant="outlined" size="large" color="success" onClick={() => handleOnHold(id)} sx={{ width: '100%' }}>Pronto</Button>
              </Grid>
            </Grid>
          )
        }

        {
          order?.status === 'on-hold' && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button variant="outlined" size="large" color="success" sx={{ width: '100%' }}>Selecione Entregador</Button>
              </Grid>
            </Grid>
          )
        }
      </Box>

      <CardStyle sx={{ my: 2 }}>
        <CardHeaderStyle subheader='Data/hora' />
        <CardContentStyle>
          {order?.created_at}
        </CardContentStyle>
      </CardStyle>

      <CardStyle sx={{ my: 2 }}>
        <CardHeaderStyle subheader='Nome' />
        <CardContentStyle>
          {
            order?.billing !== undefined && order?.billing ? (
              JSON.parse(order.billing).map((buyer, index) => {
                return (
                  <Fragment key={index}>
                    <Typography variant="body1" >{buyer.first_name} {buyer.last_name}</Typography>
                    <Typography variant="body1" >{buyer.phone}</Typography>
                  </Fragment>
                )
              })
            ) : ''
          }
        </CardContentStyle>
      </CardStyle>

      <CardStyle sx={{ my: 2 }}>
        <CardHeaderStyle subheader='Itens' />
        <CardContentStyle>
          {
            order?.items !== undefined && order?.items ? (
              JSON.parse(order?.items).map((item, index) => {
                return (
                  <Stack direction="row" spacing={2} key={index}>
                    <Typography variant="body1" >{item.name} - {item.quantity}</Typography>
                    <Typography variant="body1" >{item.additions}</Typography>
                  </Stack>
                )
              })
            ) : ''
          }
        </CardContentStyle>
      </CardStyle>

      <CardStyle sx={{ my: 2 }}>
        <CardHeaderStyle subheader='Valor Final' />
        <CardContentStyle>
          {order?.total}
        </CardContentStyle>
      </CardStyle>


      <CardStyle sx={{ my: 2 }}>
        <CardHeaderStyle subheader='Nome' />
        <CardContentStyle>
          {
            order?.billing !== undefined && order?.billing ? (
              JSON.parse(order?.billing).map((buyer, index) => {
                return (
                  <Fragment key={index}>
                    <Typography variant="body1" >{buyer.first_name} {buyer.last_name}</Typography>
                    <Typography variant="body1" >{buyer.phone}</Typography>
                    <Typography variant="body1" >{buyer.address}, {buyer.district}</Typography>
                    <Typography variant="body1" >{buyer.postal_code}</Typography>
                    <Typography variant="body1" >{buyer.city} - {buyer.state}</Typography>
                  </Fragment>
                )
              })
            ) : ''
          }
        </CardContentStyle>
      </CardStyle>
    </RootStyle>
  )
}

export default DrawerDetail
